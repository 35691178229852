import { createStore } from "vuex";
import { log } from "./irc/client";

export const store = createStore({
  state() {
    return {
      nick: null,
      loginModal: true,
      passwordModal: false,

      isLoggedIn: false,
      irc: 0,
      rooms: [],
    };
  },

  getters: {

    loginModal: (state) => {
      return state.loginModal;
    },

    passwordModal: (state) => {
      return state.passwordModal;
    },

    rooms(state) {
      return state.rooms;
    },

    irc(state) {
      return state.irc;
    },

    nick(state) {
      return state.nick;
    },

    isLoggedIn(state) {
      return state.isLoggedIn;
    },
  },

  mutations: {
    setModal(state, payload) {
      
      state.loginModal = false;
      log(payload);      
    },
    setIrc(state, ws) {
      console.log("Setting IRC:", ws);
      state.irc = ws;
    },
    setNick(state, nick) {
      state.nick = nick;
    },
    setIsLoggedIn(state, isLoggedIn) {
      console.log("Setting isLoggedIn:", isLoggedIn);
      state.isLoggedIn = isLoggedIn;
    },
  },
});