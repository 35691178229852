<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="w-[40rem] bg-white my-10 h-fit rounded-md p-4 text-black" ref="reportIncidentModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">Hello, {{ store.userName }}</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideReportIncidentModal()" />
            </div>
            <hr>
            <form @submit.prevent="onSendReportncident">

                <div>
                    <p class="my-2 text-sm text-left">Please provide the reason why you are reporting this user to the moderators.</p>
                    <p class="my-2 text-sm text-left font-bold">We take all reports seriously. Do not abuse the report feature!</p>
                    <textarea rows="4" name="comment" id="comment" v-model="reportIncident" placeholder="Type your message here..." class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
                <div class="py-2">
                    <button type="submit" class="bg-custom-secondary w-full py-2 rounded-md my-1">Send Report</button>
                </div>
            </form>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md my-1" @click="store.hideReportModal">Close</button>
            </div>
        </div>
    </div>
</template>


<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import { sendReport } from '@/irc/client.js';

const store = useMainStore();
const reportIncidentModalRef = ref(null);


onClickOutside(reportIncidentModalRef, store.hideReportIncidentModal);

const onSendReportncident = () => {
    let reportMessage = store.userName + ' report this : '+ reportIncident.value
    sendReport(reportMessage);

    reportIncident.value = "";
}

const reportIncident = ref("");
</script>
