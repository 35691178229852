<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="w-[100vw] md:w-[40rem] bg-white my-10 h-fit rounded-md p-2 text-black" ref="shareModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">Share {{ store.activeRoom }}</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" @click="store.hideShareModal" />
            </div>
            <hr>
            <div class="ml-6 my-4 text-left">
                <p class="text-base my-1">Share the following direct link.</p>
                <p class="text-sm my-1">This URL will join someone directly to this room when coming from another website.
                </p>
                <div class="flex my-2">
                    <input type="text" class="border border-black w-full px-2 rounded-sm text-sm sm:text-lg"
                        :value="urlValue" readonly>
                    <button class="bg-custom-secondary text-white py-2 px-4 flex" @click="copyURL()" >
                        <DocumentDuplicateIcon class="h-6 w-6 text-white"/>
                        Copy
                    </button>
                </div>
                <div class="h-10">
                    <p v-if="copyMessage">Copied!</p>
                </div>
            </div>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideShareModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { DocumentDuplicateIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { ref } from 'vue';
const store = useMainStore();
const shareModalRef = ref(null)

const urlValue = "https://www.abigchat.com/" + store.activeRoom;

const copyMessage = ref(false);

onClickOutside(shareModalRef, store.hideShareModal);

const copyURL = () => {
  const tempInput = document.createElement('input');
  tempInput.value = urlValue;

  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');

  document.body.removeChild(tempInput);

  copyMessage.value = true

  setTimeout(() => {
    copyMessage.value = false
  }, 3000)
};

</script>
