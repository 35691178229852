import { useMainStore } from "@/mainStore";

export default class WebRTC {


    constructor(nickname) {
        this.peerConnectionConfig = {
            'iceServers': [
                { 'urls': 'stun:stun.stunprotocol.org:3478' },
                { 'urls': 'stun:stun.l.google.com:19302' },
            ],
        };

        this.store = useMainStore();
        this.nickname = nickname;
        this.selfname = this.store.userName;

        this.peerConnection = this.createPeerConnection();

        this.store.addPeer({
            nickname: nickname,
            connection: this.peerConnection,
        });

        console.log("Created peer connection", this.store.localStream);
    }

    requestEntry() {

        //Let other know
        this.store.socket.emit("requestEntry",  {
            from: this.selfname,
            to: this.nickname,
        });

        this.store.setBroadcastWaiting(true);
    }

    createPeerConnection() {
        let rtcConnection = new RTCPeerConnection(this.peerConnectionConfig);

        rtcConnection.onicecandidate = (event) => {
            // console.log("Sending candidate", event);
            if (event.candidate != null) {
                this.store.socket.emit("candidate", {
                    from: this.selfname,
                    to: this.nickname,
                    ice: event.candidate,
                });                
            }
        };

        if (this.store.localStream) {
            // console.log("Adding tracks:");
            try{
                this.store.localStream.getTracks().forEach((track) => {
                    // console.log("track", track);
                    rtcConnection.addTrack(track, this.store.localStream);
                });    
            }catch(e){
                console.error(e);
            }
        }

        rtcConnection.ontrack = (event) => {
            this.store.setRemoteStream( event.streams[0]);
        };

        rtcConnection.oniceconnectionstatechange = () => {

            if (rtcConnection.iceConnectionState == "disconnected") {
                console.log(this.nickname, "disconnected");

                this.store.removePeer(
                    this.nickname
                );

                this.store.setRemoteStream(null);
            }
        }

        return rtcConnection;
    }

    sendOffer() {
        this.peerConnection.createOffer(
            (offer) => {
               
                //Let other know
                this.store.socket.emit("offer", {
                    from: this.selfname,
                    to: this.nickname,
                    offer: offer,
                });

                this.peerConnection.setLocalDescription(offer);
            },
            function (error) {
                alert("Error when creating an offer: " + error);
            }
        );
    }

    handleOffer(data) {
        // console.log("Incoming offer", data);
        this.answer(data);
    }

    answer(signal) {
        this.store.setBroadcastWaiting(false);

        if (this.store.peers && !this.store.peers[signal.from]) {
            // console.log("Creating new peer", this.store.peers);
        }

        try{
            this.store.peers[signal.from].setRemoteDescription(
                new RTCSessionDescription(signal.offer)
            );

            this.store.setActiveBroadcaster(signal.from);
    
            if (signal.offer.type != "offer") {
                return;
            }
    
            //Create and send answer
            this.store.peers[signal.from].createAnswer().then((answer) => {
                this.peerConnection.setLocalDescription(answer);
    
            
                //Let other know
                this.store.socket.emit("answer",  {
                    from: this.selfname,
                    answer: answer,
                    to: signal.from
                });
            });
    
        }catch(e){ console.error(e) }
    }

    static handleAnswer(
        signal
    ) {
        var store = useMainStore();

        if (store.peers && !store.peers[signal.from]) {
            console.error("Invalid answer from: ", signal.from);
        }

        store.peers[signal.from].setRemoteDescription(
            new RTCSessionDescription(signal.answer)
        );
    }

    static handleCandidate(signal) {
        var store = useMainStore();

        try{
            store.peers[signal.from].addIceCandidate(
                new RTCIceCandidate(signal.ice)
            );    
        }catch(e){
            console.log(e);
        }
        
    }
}
