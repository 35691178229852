<template>
    <div class="flex flex-col flex-1 min-h-0 bg-white">
        <div class="relative flex flex-1 min-h-0" :class="containerCls">
            <SideBar v-if="store.sideBar"/>
            <div class="flex flex-col flex-1 min-h-0 w-full break-words overflow-hidden">
                <div class="bg-gray-400 rounded-md text-left text-xs md:text-sm" v-for="list in store.activeRoomList" :key="list">
                    <p v-if="store.activeRoom == list.name" class="mx-1 h-[20px] whitespace-nowrap">
                        <span class="text-custom-secondary">{{ list.name }}</span>
                        <span class="ml-1" v-if="list.count">[{{ store.userLength }}]</span>
                        <span v-if="list.topic">::<span v-html="parseIRCColours(list.topic)"></span></span>
                    </p>
                </div>
                <MessageBox />
            </div>
        </div>

        <MessageInput />

        <BroadcastView v-show="store.broadcasting" />
        <BroadcastRemoteView v-show="store.remoteStream" />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '@/mainStore';
import BroadcastView from "../components/BroadcastView";
import { parseIRCColours } from '@/helpers';
const store = useMainStore();
const containerCls = computed(() => store.sideBarInLeft ? "flex-row" : "flex-row-reverse");
</script>

<script>
import SideBar from '@/components/SideBar.vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageInput from '@/components/MessageInput.vue';
import BroadcastRemoteView from '@/components/BroadcastRemoteView.vue';

export default {
    name: 'MessageModal',
    components: {
        MessageBox,
        SideBar,
        MessageInput,
    },
}
</script>