<script setup>
import { useMainStore } from '@/mainStore';
const store = useMainStore();

</script>


<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container" @click="store.hideHelpModal">
        <div class="absolute left-0 top-24 w-[25rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">The "Menu" button will show you various useful links. </p>
            <p class="mb-2">You can change some chat options or your font style that others will see.</p>
        </div>
        <div class="absolute left-[25rem] top-20 w-[35rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">The "Rooms" button shows you a list of all the rooms you are in. </p>
            <p class="mb-2">Touch the "List" button to view other rooms on the server.</p>
            <p class="mb-2">The "Messages" button is where all of your private conversations are managed.</p>
            <p class="mb-2">A <span class="bg-red-600 font-bold text-white rounded-md">bubble</span> appears when you have unread messages!</p>
            <p class="mb-2">The triangle wedge button quickly switches between your two most recent tabs.</p>
        </div>
        <div class="absolute right-0 top-20 w-[35rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">The "Leave/End close tebs"</p>
            <p class="mb-2">(You <i>"leave"</i> rooms, <i>"end"</i> private conversations)</p>
            <p class="mb-2">The current tab you are chatting to is labeled to the far right.</p>
        </div>
        <div class="absolute left-8 top-[30rem] w-[35rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">Messages sent by you or anyone else will appear in this main box.</p>
        </div>
        <div class="absolute right-8 top-[30rem] w-[25rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">In room tabs, a userlist will be available showing all users currently in the room.</p>
            <p class="mb-2">You can touch on a name in the list to expand a menu for that person.</p>
            <p class="mb-2">Staff members usually appear at the top of the list, and have symbols in front of their names.</p>
            <p class="mb-2">Depending on the size of your device or your browser window, the userlist may be hidden automatically.</p>
            <p class="mb-2">Touch the  <strong> in the lower right corner to show or hide the userlist.</strong></p>
        </div>
        <div class="absolute left-0 bottom-[4rem] w-[25rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">Touch the <strong>Gif button</strong> to upload a Gif.</p>
        </div>
        <div class="absolute left-[30rem] bottom-[4rem] w-[35rem] text-left text-sm bg-white px-4 py-1 border border-black rounded-md">
            <p class="mb-2">Type messages here to send something to the current tab.</p>
        </div>
    </div>
</template>
