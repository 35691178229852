<template>
    <div>
        <div @touchstart="grabVideoHolder" @touchend="releaseVideoHolder" @mousedown="grabVideoHolder"
            @mouseup="releaseVideoHolder" id="remote-video-holder-container" class="video-holder">
            <video id="remote-video-holder" playsinline autoplay></video>
            <div class="remote-video-holder-actions" v-if="false">
                <div class="flex justify-around text-center">
                    <div>
                        <!-- <EyeIcon class="text-white block" /> -->
                        <span class="text-white block">10</span>
                    </div>
                    <div>
                        <!-- <EyeIcon class="text-white block" /> -->
                        <span class="text-white block">10</span>
                    </div>
                    <div>
                        <!-- <EyeIcon class="text-white block" /> -->
                        <span class="text-white block">10</span>
                    </div>
                </div>
            </div>
            <div @click="stopViewing" class="video-close-btn">X</div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useMainStore } from '@/mainStore';
const store = useMainStore();

const remoteVideo = ref();
const videoHolderGrabbed = ref(false);
const grabPosition = ref({});

onMounted(() => {
    remoteVideo.value = document.getElementById("remote-video-holder-container");

    window.document.addEventListener("mousemove", dragHolder);
    window.document.addEventListener("touchmove", dragHolder);

    window.document.addEventListener("mouseup", releaseVideoHolder);
    window.document.addEventListener("touchend", releaseVideoHolder);

});


function stopViewing() {
    if (store.activeBroadcaster && store.peers[store.activeBroadcaster]) {
        store.peers[store.activeBroadcaster].close();
        store.removePeer(store.activeBroadcaster);
        store.deleteRemoteStream();
        store.removeActiveBroadcaster();
    }
}

function dragHolder(e) {
    if (videoHolderGrabbed.value) {
        if (e.touches && e.touches.length > 0) {
            e = e.touches[0];
        }

        if (remoteVideo.value) {
            remoteVideo.value.style.top =
                e.clientY + grabPosition.value.yDiff + "px";
            remoteVideo.value.style.left =
                e.clientX + grabPosition.value.xDiff + "px";
        }
    }
}

function grabVideoHolder(e) {
    if (e.touches && e.touches.length > 0) {
        e = e.touches[0];
    }

    remoteVideo.value.classList.add("grabbed");

    videoHolderGrabbed.value = true;
    var bounds = remoteVideo.value.getBoundingClientRect();
    grabPosition.value.yDiff = bounds.top - e.clientY;
    grabPosition.value.xDiff = bounds.left - e.clientX;
}

function releaseVideoHolder() {
    remoteVideo.value.classList.remove("grabbed");

    videoHolderGrabbed.value = false;
}
</script>
  