<template>
    <div>
        <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
            id="modal-container">
            
                <div class="bg-white my-10 h-fit rounded-md p-2 text-black" ref="roomListModal" id="modal">
                    <div class="text-left mb-2">
                        <div class="flex justify-between">
                            <h3 class="text-lg font-semibold leading-6 text-gray-900 mb-4">Broadcasting Joining Requests</h3>
                            <button @click="store.hideRoomList">
                                <XMarkIcon class="h-6 w-6 text-gray-300 hover:text-gray-700" />
                            </button>
                        </div>

                        <table>
                            <thead class="border-b border-gray-200">
                                <tr className="px-4">
                                    <th class="w-48 text-center">
                                        <div class="flex gap-1 items-center">
                                            <GlobeAmericasIcon class="h-4 w-4" />
                                            <span class="hidden md:block">Nickname</span>
                                        </div>
                                    </th>
                                    <th class=" text-center flex justify-center">
                                        <div class="flex gap-1  justify-center items-center">
                                            <UserIcon class="h-4 w-4" />
                                            <span class="hidden md:block">Actions</span>
                                        </div>
                                    </th>                                   
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                <tr class="mx-4" v-for="(user, index) in store.pendingPermissions" :key="index">
                                    <td>{{user}}</td>
                                    <td>
                                        <button @click="approveBroadcastEntry(user)" type="button" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Approve</button>
                                        <button @click="declineBroadcastEntry(user)" type="button" class="ml-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Decline</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <hr>
                    <div class="text-right mt-3" @click="store.hideRoomList">
                        <button class="bg-custom-secondary p-2 px-4 text-white rounded-md">Close</button>
                    </div>
                </div>
           
        </div>

    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue';
import { GlobeAmericasIcon, UserIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import {approveBroadcastEntry, declineBroadcastEntry} from "@/irc/broadcaster";

const store = useMainStore()

const roomListModal = ref(null)

onClickOutside(roomListModal, store.hideBroadcastPendingRequests);

</script>
