<template>
    <div>
        <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
            id="modal-container">
            
                <div class="bg-white my-10 h-fit rounded-md p-2 text-black" ref="roomListModal" id="modal">
                    <div class="text-left mb-2">
                        <div class="flex justify-between">
                            <h3 class="text-lg font-semibold leading-6 text-gray-900 mb-4">Room List</h3>
                            <button @click="store.hideRoomList">
                                <XMarkIcon class="h-6 w-6 text-gray-300 hover:text-gray-700" />
                            </button>
                        </div>

                        <table>
                            <thead class="border-b border-gray-200">
                                <tr>
                                    <th class="w-48 text-center">
                                        <div class="flex gap-1 justify-center items-center">
                                            <GlobeAmericasIcon class="h-4 w-4" />
                                            <span class="hidden md:block">Room</span>
                                        </div>
                                    </th>
                                    <th class="text-center flex justify-center">
                                        <div class="flex gap-1 justify-center items-center">
                                            <UserIcon class="h-4 w-4" />
                                            <span class="hidden md:block">Users</span>
                                        </div>
                                    </th>
                                    <th class="w-48 text-center">
                                        <div class="flex gap-1  justify-center items-center">
                                            <InformationCircleIcon class="h-4 w-4" />
                                            <span class="hidden md:block">Topic</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                <RoomItem v-for="(room, id) in sortedRoomList" :key="id" :room="room" />
                            </tbody>
                        </table>

                    </div>
                    <hr>
                    <div class="text-right mt-3" @click="store.hideRoomList">
                        <button class="bg-custom-secondary p-2 px-4 text-white rounded-md">Close</button>
                    </div>
                </div>
           
        </div>

    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import RoomItem from "@/components/RoomItem.vue";
import { onClickOutside } from '@vueuse/core'
import { ref, computed } from 'vue';
import { GlobeAmericasIcon, InformationCircleIcon, UserIcon, XMarkIcon } from '@heroicons/vue/24/solid';
const store = useMainStore()

const sortedRoomList = computed(() => {
    return [...store.roomList].sort((a, b) => b.count - a.count);
});

const roomListModal = ref(null)

onClickOutside(roomListModal, store.hideRoomList);


</script>
