import { createApp } from 'vue'
import App from './App.vue'
import "./index.css";
import "@/irc/eventHandler";
import cors from 'cors';
import { createPinia } from "pinia";
import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog'
const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(cors);
app.use(Vue3ConfirmDialog);
app.mount("#app");