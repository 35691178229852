<template>
    <div>
        <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
            id="modal-container">
            
                <div class="bg-white my-10 h-fit rounded-md p-2 text-black" ref="roomListModal" id="modal">
                    <div class="text-left mb-2">
                        <div class="flex justify-between">
                            <h3 class="text-lg font-semibold leading-6 text-gray-900 mb-4">Waiting for broadcaster to let you in...</h3>
                            <button @click="store.hideRoomList">
                                <XMarkIcon class="h-6 w-6 text-gray-300 hover:text-gray-700" />
                            </button>
                        </div>

                    </div>
                    <hr>
                    <div class="text-right mt-3" @click="store.hideRoomList">
                        <button class="bg-custom-secondary p-2 px-4 text-white rounded-md">Close</button>
                    </div>
                </div>
           
        </div>

    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

const store = useMainStore()

const roomListModal = ref(null)

onClickOutside(roomListModal, ()=>{
    store.setBroadcastWaiting(false);
});

</script>
