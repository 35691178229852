<template>
  <div>
    <div @touchstart="grabVideoHolder" @touchend="releaseVideoHolder" @mousedown="grabVideoHolder"
      @mouseup="releaseVideoHolder" id="video-holder-container" class="video-holder">
      <video muted id="local-video-holder" playsinline autoplay></video>
      <div class="video-holder-actions">
        <div class="flex justify-around pt-1 items-center text-center">
          <div class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 inline h-8 text-white">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span class="text-white block">{{ store.peerCount }}</span>
          </div>
          <div class="text-center">
            <!-- Mute -->
            <svg v-if="muted" @click="unMuteAudio" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-red cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
            </svg>

            <!-- Unmute -->
            <svg v-if="!muted" @click="muteAudio" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-white cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
            </svg>


            <span class="text-white block">Audio</span>
          </div>
          <div class="text-center">

            <!-- Enable camera -->
            <svg v-if="videoEnabled" @click="disableVideo" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-8 h-8 inline text-white cursor-pointer">
              <path stroke-linecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>


            <!-- Disable camera -->
            <svg v-if="!videoEnabled" @click="enableVideo" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-red cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
            </svg>


            <span class="text-white block">Video</span>

          </div>
        </div>
      </div>
      <div @click="stopBroadcast" class="video-close-btn">X</div>
    </div>
  </div>
</template>


<style>
.video-holder,
.remote-video-holder {
  width: 200px;
  border: 2px solid #0000;
  background-color: #000099;
  position: fixed;
  top: 62%;
  right: 25%;
  z-index: 999;
  border-radius: 1rem;
}

@media only screen and (min-width: 600px) {

  .video-holder,
  .remote-video-holder {
    right: 17%;
  }

}


.video-holder video,
.remote-video-holder video {
  border-radius: 1rem;
}

.video-holder-actions,
.remote-video-holder-actions {
  padding: 2px 5px;
}

.flex {
  display: flex;
}

.justify-around {
  justify-content: space-around;
}

.block {
  display: block;
}


.grabbed {
  cursor: move;
}

#local-video-holder,
#remote-video-holder {
  width: 100%;
  height: 100%;
}

.broadcast-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  transition: 0.5s;
}

.broadcast-icon:hover {
  background-color: rgba(224, 224, 119, 0.5);
}

.text-red {
  color: red;
}

.video-close-btn {
  position: absolute;
  top: 4px;
  color: gray;
  font-size: 17px;
  right: 9px;
  cursor: pointer;
  transition: 1s;
}

.video-close-btn:hover {
  color: black;
}

.justify-between {
  justify-content: space-between;
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import { useMainStore } from '@/mainStore';
import { stopBroadcasting } from '@/irc/broadcaster';
const store = useMainStore();

const state = ref();
const videoHolderGrabbed = ref(false);
const grabPosition = ref({});

console.log(state);

onMounted(() => {
  store.setLocalVideo(document.getElementById("video-holder-container"));


  window.document.addEventListener("mousemove", dragHolder);
  window.document.addEventListener("touchmove", dragHolder);

  window.document.addEventListener("mouseup", releaseVideoHolder);
  window.document.addEventListener("touchend", releaseVideoHolder);
});

function dragHolder(e) {
  if (videoHolderGrabbed.value) {
    if (e.touches && e.touches.length > 0) {
      e = e.touches[0];
    }

    if (store.localVideo) {
      store.localVideo.style.top =
        e.clientY + grabPosition.value.yDiff + "px";
      store.localVideo.style.left =
        e.clientX + grabPosition.value.xDiff + "px";
    }
  }
}

function releaseVideoHolder() {
  store.localVideo.classList.remove("grabbed");

  videoHolderGrabbed.value = false;
}

function grabVideoHolder(e) {
  if (e.touches && e.touches.length > 0) {
    e = e.touches[0];
  }

  store.localVideo.classList.add("grabbed");

  videoHolderGrabbed.value = true;
  var bounds = store.localVideo.getBoundingClientRect();
  grabPosition.value.yDiff = bounds.top - e.clientY;
  grabPosition.value.xDiff = bounds.left - e.clientX;
}

function stopBroadcast() {
  store.toggleBroadcaster();
  stopBroadcasting();
}

</script>

<script>

export default {
  name: "BroadcastView",

  components: {
  },

  data() {
    return {
      videoEnabled: true,
      muted: false,
      localVideo: null,
      localStream: null,
      videoHolderGrabbed: false,
      grabPosition: {
        xDiff: null,
        yDiff: null,
      },
    };
  },

  mounted() {

  },

  expose: ['muteAudio', 'unMuteAudio', 'enableVideo', 'disableVideo'],

  methods: {
    muteAudio() {
      let localVideoElement = document.getElementById("local-video-holder");
      localVideoElement.srcObject.getAudioTracks()[0].enabled = false;
      this.muted = true;
    },

    unMuteAudio() {
      let localVideoElement = document.getElementById("local-video-holder");
      localVideoElement.srcObject.getAudioTracks()[0].enabled = true;
      this.muted = false;
    },

    enableVideo() {
      let localVideoElement = document.getElementById("local-video-holder");
      localVideoElement.srcObject.getVideoTracks()[0].enabled = true;
      this.videoEnabled = true;
    },

    disableVideo() {
      let localVideoElement = document.getElementById("local-video-holder");
      localVideoElement.srcObject.getVideoTracks()[0].enabled = false;
      this.videoEnabled = false;
    },


    dragHolder(e) {
      if (this.videoHolderGrabbed) {
        if (e.touches && e.touches.length > 0) {
          e = e.touches[0];
        }

        if (this.localVideo) {
          this.localVideo.style.top =
            e.clientY + this.grabPosition.yDiff + "px";
          this.localVideo.style.left =
            e.clientX + this.grabPosition.xDiff + "px";
        }
      }
    },

    releaseVideoHolder() {
      this.localVideo.classList.remove("grabbed");

      this.videoHolderGrabbed = false;
    },
  },

};
</script>
