<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
        id="modal-container">
        <div class="w-[100vw] md:w-[50vw] bg-white my-10 h-fit rounded-md p-2 text-black" ref="optionModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">OptionsDialog</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideOptionModal" />
            </div>
            <hr>
            <div class="py-4 text-left my-1 md:my-6 flex flex-col md:flex-row">
                <button
                    :class="[store.activeTab == 'private' ? 'bg-red-500 p-2 px-3 rounded-md text-white' : 'p-2 px-3 rounded-md  mx-1 hover:bg-gray-100 text-red-500']"
                    @click="store.setActiveOption('private')">Personal Settings</button>
                <button
                    :class="[store.activeTab == 'appearance' ? 'bg-red-500 p-2 px-3 rounded-md text-white' : 'p-2 px-3 rounded-md  mx-1 hover:bg-gray-100 text-red-500']"
                    @click="store.setActiveOption('appearance')">Activity</button>
                <button
                    :class="[store.activeTab == 'sound' ? 'bg-red-500 p-2 px-3 rounded-md text-white' : 'p-2 px-3 rounded-md  mx-1 hover:bg-gray-100 text-red-500']"
                    @click="store.setActiveOption('sound')">Animations</button>
                <button
                    :class="[store.activeTab == 'theam' ? 'bg-red-500 p-2 px-3 rounded-md text-white' : 'p-2 px-3 rounded-md  mx-1 hover:bg-gray-100 text-red-500']"
                    @click="store.setActiveOption('theam')">Optics</button>
            </div>

            <!-- private tab -->

            <div class="text-left mb-6" v-if="store.activeTab == 'private'">
                <div class="ml-5 my-1">
                    <input type="checkbox" name="private-message" id="private-message" class="mx-2 accent-red-600"
                        :checked="store.enablePrivateMessage" @click="togglePrivateMessageAccess">
                    <label for="private-message">Enable Private Message</label>
                </div>
                <div class="ml-5 my-1" v-if="store.enablePrivateMessage">
                    <input type="checkbox" name="block-private-male" id="block-private-male" class="mx-2 accent-red-600"
                        :checked="store.blockMaleUser" @click="store.toggleBlockMaleUser">
                    <label for="block-private-male">Block private from male users</label>
                </div>
                <div class="ml-5 my-1" v-if="store.enablePrivateMessage">
                    <input type="checkbox" name="block-private-female" id="block-private-female" class="mx-2 accent-red-600"
                        :checked="store.blockFemaleUser" @click="store.toggleBlockFemaleUser">
                    <label for="block-private-female">Block private from female users</label>
                </div>
                <div class="ml-5 my-1" v-if="store.enablePrivateMessage">
                    <input type="checkbox" name="block-private-other" id="block-private-other" class="mx-2 accent-red-600"
                        :checked="store.blockOtherUser" @click="store.toggleBlockOtherUser">
                    <label for="block-private-other">Block private from other users</label>
                </div>
                <div class="ml-5 my-1" v-if="store.enablePrivateMessage">
                    <input type="checkbox" name="block-guest" id="block-guest" class="mx-2 accent-red-600">
                    <label for="block-guest">Block private from guest users</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="background-message" id="background-message" class="mx-2 accent-red-600">
                    <label for="background-message">Open Private Messages in Background</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="block-whispers" id="block-whispers" class="mx-2 accent-red-600"
                        :checked="store.blockWhisper" @click="store.toggleWhisper()">
                    <label for="block-whispers">Block Whispers</label>
                </div>
            </div>

            <!-- Appearance tab -->

            <div class="text-left mb-6" v-if="store.activeTab == 'appearance'">
                <div class="ml-5 my-1">
                    <input type="checkbox" name="show-timestamps" id="show-timestamps" class="mx-2 accent-red-600"
                        :checked="store.showTimeStamps" @click="store.toggleTimestampsVisible()">
                    <label for="show-timestamps">Show Timestamps</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="show-joins" id="show-joins" class="mx-2 accent-red-600"
                        :checked="store.joinUserMessageVisible" @click="store.toggleJoinUserMessage">
                    <label for="show-joins">Show Joins</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="show-quit" id="show-quit" class="mx-2 accent-red-600"
                        :checked="store.quitUserMessageVisible" @click="store.toggleQuitUserMessage">
                    <label for="show-quit">Show Quits/Leaves</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="strip-color" id="strip-color" class="mx-2 accent-red-600">
                    <label for="strip-color">Strip Colors from Messages</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="allow-arrowkey" id="allow-arrowkey" class="mx-2 accent-red-600">
                    <label for="allow-arrowkey">Allow arrow key shortcuts</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="userList-left" id="userList-left" class="mx-2 accent-red-600"
                        :checked="store.sideBarInLeft" @click="store.toggleSideBarPosition()">
                    <label for="userList-left">Userlist on Left Side</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="update-scroll" id="update-scroll" class="mx-2 accent-red-600"
                        :checked="store.updateScroll" @click="store.toggleUpdateScroll()">
                    <label for="update-scroll">Update scroll to bottom</label>
                </div>
                <div class="ml-5 my-1" v-if="false">
                    <input type="checkbox" name="navbar-button" id="navbar-button" class="mx-2 accent-red-600"
                        :checked="store.navbarAtBottom" @click="store.toggleNavbarPosition()">
                    <label for="navbar-button">Navbar at Bottom</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="expand-tabBar" id="expand-tabBar" class="mx-2 accent-red-600"
                        :checked="store.expandActiveRoomList" @click="store.toggleExtendedRoomList()">
                    <label for="expand-tabBar">Expanded Tab Bar on Desktop</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="user-avatar" id="user-avatar" class="mx-2 accent-red-600"
                        :checked="store.enableUserListAvatar" @click="store.toggleUserListAvatar">
                    <label for="user-avatar">Show Userlist Avatars (Requires Reload)</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="message-avatar" id="message-avatar" class="mx-2 accent-red-600"
                        :checked="store.enableMessageAvatar" @click="store.toggleMessageAvatar">
                    <label for="message-avatar">Show Message Avatars (Only Applies to New Messages)</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="enable-notification" id="enable-notification" class="mx-2 accent-red-600" :checked="store.notificationOnTab" @click="store.toggleNotificationOnTab()">
                    <label for="enable-notification">Enable Tab Notifications</label>
                </div>
                <!-- <div class="ml-5 my-1">
                    <label for="backlog-line">Message Backlog Lines</label> <br>
                    <input type="range" name="backlog-line" id="backlog-line" min="50" max="1000" class="w-[50%]">
                </div> -->
                <div class="ml-5 my-1">
                    <label for="font-scale">Message Font Scale {{ store.messageTextSize }}</label><br>
                    <input v-model="store.messageTextSize" type="range" name="font-scale" id="font-scale" min="5" max="20"
                        class="w-[50%] accent-red-600">
                </div>
            </div>

            <!-- Sound tab -->

            <div class="text-left mb-6" v-if="store.activeTab == 'sound'">
                <div class="ml-5 my-1">
                    <input type="checkbox" name="enable-sound" id="enable-sound" class="mx-2 accent-red-600"
                        @click="store.toggleNotificationSound" :checked="store.notificationSound">
                    <label for="enable-sound">Enable Sound</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="highlights-sound" id="highlights-sound" class="mx-2 accent-red-600">
                    <label for="highlights-sound">Sound on Highlights</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="private-sound" id="private-sound" class="mx-2 accent-red-600">
                    <label for="private-sound">Sound on Private Message</label>
                </div>
                <div class="ml-5 my-1">
                    <input type="checkbox" name="pVibrations" id="Vibrations" class="mx-2 accent-red-600"
                        :checked="store.vibrationOnMessage" @click="store.toggleVibration">
                    <label for="Vibrations">Vibrations</label>
                </div>
            </div>

            <!-- Theme tab -->

            <div class="text-left mb-6" v-if="store.activeTab == 'theam'">
                <hr>
                <div class="py-10 ml-8">
                    <select name="theme" id="theme" class="p-2 rounded-md mx-2">
                        <option value="default">default</option>
                        <option value="bling">bling</option>
                        <option value="bnw">bnw</option>
                        <option value="bubble">bubble</option>
                        <option value="bubbles">bubbles</option>
                        <option value="nocturnal">nocturnal</option>
                        <option value="portals">portals</option>
                    </select>Present Themes
                </div>
            </div>

            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideOptionModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue';
const store = useMainStore();

const optionModalRef = ref(null)

onClickOutside(optionModalRef, store.hideOptionModal);


const togglePrivateMessageAccess = () => {
    store.togglePrivateMessage();
}

</script>

<style>.private {
    background-color: blue;
}</style>