<template>
  <div>
      <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="bg-white my-10 h-fit rounded-md p-2 text-black" ref="killedModal" id="modal">
            <div class="text-left mb-2">
              <div class="flex justify-between">
                <h3 class="text-lg font-semibold leading-6 text-gray-900 mb-4">You are disconnected</h3>
                <button @click="store.hideKilledModal">
                  <XMarkIcon class="h-6 w-6 text-gray-300 hover:text-gray-700" />
                </button>
              </div>
            </div>
            <hr>

            <div>
              <span v-html="parseIRCColours(store.killedMessage)"></span>
            </div>

            <div class="text-right mt-3" @click="store.hideKilledModal">
              <button class="bg-custom-secondary p-2 px-4 text-white rounded-md">Sign back in</button>
            </div>
        </div>
      </div>
  </div>
</template>

<script setup>
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref, computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { parseIRCColours } from '@/helpers';
const store = useMainStore()

const killedModal = ref(null)

onClickOutside(killedModal, store.hideKilledModal);

</script>
