<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
        id="modal-container">
        <div class="w-[100vw] sm:w-[50vw] md:w-[40vw] xl:w-[30vw] bg-white my-40 h-fit rounded-md p-2 text-black"
            ref="profileModalRef" id="profileModal">
            <div class="flex justify-between items-center px-4 py-1">
                <p class="text-2xl">Profile</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideProfileModal" />
            </div>
            <hr>
            <div class="my-2 text-center">
                <div class="gap-4 flex flex-col justify-center">
                    <div class="flex justify-center">
                        <img v-if="store.userProfile.profilePhoto"
                            :src="require(`@/../public/profilePhoto/${store.userProfile.profilePhoto}`)" alt=""
                            class="h-24 w-24 cursor-pointer rounded-full"
                            @click="handleProfilePhotoClick(store.userProfile)">
                        <img v-else
                            :src="`https://ui-avatars.com/api/?name=${store.userProfile.userName}/&background=random&rounded=true&size=64`"
                            alt="" class="h-24 w-24 cursor-pointer" @click="handleProfilePhotoClick(store.userProfile)">
                    </div>
                    <p>{{ store.userProfile.userName }}</p>
                    <div class=" flex justify-center gap-8">
                        <p>{{ store.userProfile.country }}</p>
                        <div class="flex items-center">
                            <div v-if="store.userProfile.gender == 'male'" class="">
                                <svg style="color: rgb(6, 89, 238);" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-gender-male mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                                        fill="#0659ee"></path>
                                </svg>
                            </div>

                            <div v-if="store.userProfile.gender == 'female'" class="">
                                <svg style="color: rgb(236, 72, 153);" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-gender-female mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"
                                        fill="#ec4899"></path>
                                </svg>
                            </div>

                            <div v-if="store.userProfile.gender == 'others'" class="">
                                <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                    fill="currentColor" class="bi bi-question mr-2" viewBox="0 0 16 16">
                                    <path
                                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
                                        fill="red"></path>
                                </svg>
                            </div>
                            <p>{{ store.userProfile.dob }}</p>
                        </div>
                    </div>

                    <!-- profile picture option for auth user -->
                    <div v-if="store.visibleProfileOption" class="absolute p-2 left-[52%] top-[30%] rounded-md bg-gray-100"
                        ref="ProfileOptionRef">
                        <ul class="text-left">
                            <li class="p-2 hover:bg-gray-300 my-2 rounded-md cursor-pointer" @click="importPhoto()">Change
                                Profile Photo</li>
                            <li class="p-2 hover:bg-gray-300 my-2 rounded-md cursor-pointer" @click="viewProfilePhoto()">
                                View
                                Profile Photo</li>
                            <li class="p-2 hover:bg-gray-300 my-2 rounded-md cursor-pointer" @click="removeProfilePhoto()">
                                Remove Profile Photo</li>
                        </ul>
                    </div>

                    <!-- View profile picture modal -->
                    <div class=" absolute top-0 left-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
                        id="modal-container" v-if="store.visibleProfilePhoto">
                        <div class="bg-white p-4 rounded-md h-fit  my-40" ref="profilePhotoRef" id="profileModal">
                            <div class="flex justify-between items-center py-2 px-3">
                                <p>User: {{ store.userProfile.userName }}</p>
                                <XMarkIcon class="h-6 w-6 text-gray-700 cursor-pointer" @click="store.hideProfilePhoto" />
                            </div>
                            <hr>
                            <div class="m-2">
                                <img v-if="store.userProfile.profilePhoto"
                                    :src="require(`@/../public/profilePhoto/${store.userProfile.profilePhoto}`)" alt=""
                                    class="max-h[800px] max-w-[800px]">
                                <img v-else
                                    :src="`https://ui-avatars.com/api/?name=${store.userProfile.userName}/&background=random&rounded=true&size=64`"
                                    alt="" class="h-[400px] w-[400px] ">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- set profile photo form -->
                <form :action="store.config.apiHost + '/updateProfilePhoto'" method="post" enctype="multipart/form-data"
                    class="hidden" id="profileForm">
                    <input type="file" name="profilePhoto" accept="image/*" id="profilePhotoInput">
                    <input type="text" name="userName" v-model="store.userName">
                    <button type="submit" id="formSubmit">Submit</button>
                </form>
            </div>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideProfileModal">Close</button>
            </div>

            <div class=" absolute top-0 left-0 h-screen w-screen flex justify-center overflow-y-auto z-20"
                id="modal-container" v-if="store.visibleUploadProfilePhoto">
                <div class="bg-white p-4 rounded-md h-fit  my-40" ref="profilePhotoRef" id="profileModal">
                    <div class="flex justify-between items-center py-2 px-3">
                        <p>Profile Photo</p>
                        <XMarkIcon class="h-6 w-6 text-gray-700 cursor-pointer" @click="store.hideUploadProfilePhoto" />
                    </div>
                    <hr>
                    <div class="m-2">
                        <div v-if="store.profilePhoto">
                            <img :src="`${store.profilePhoto}`" alt="" class="h-[400px] w-[[400px]]">
                        </div>
                        <div v-else>
                            <img :src="`https://ui-avatars.com/api/?name=${store.userProfile.userName}/&background=random&rounded=false&size=64`"
                                alt="" class="h-[400px] w-[400px]">
                        </div>
                    </div>

                    <hr>
                    <div class="my-4 text-right">
                        <button class="py-2 px-4 border border-gray-500 rounded-md bg-gray-200 mx-2"
                            @click="store.hideUploadProfilePhoto">Cancle</button>
                        <button class="py-2 px-6 border border-gray-500 rounded-md bg-custom-secondary mx-2"
                            @click="updateProfilePhoto()">Save</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { onClickOutside } from '@vueuse/core'
import axios from 'axios';
import { ref } from 'vue';
const store = useMainStore();

const profileModalRef = ref(null)
const ProfileOptionRef = ref(null)
const profilePhotoRef = ref(null)

if (!store.visibleProfilePhoto) {
    onClickOutside(profileModalRef, store.hideProfileModal,);
}

onClickOutside(ProfileOptionRef, store.hideProfileOption);
onClickOutside(profilePhotoRef, store.hideProfilePhoto);

const handleProfilePhotoClick = (user) => {
    if (store.userName == user.userName && user.type == 'user') {
        store.showProfileOption()
    } else {
        store.showProfilePhoto()
    }

}

const viewProfilePhoto = () => {
    store.showProfilePhoto()
}

const importPhoto = () => {
    const input = document.getElementById('profilePhotoInput')
    input.click()
    input.onchange = () => {
        let files = Array.from(input.files);
        store.showUploadProfilePhoto();
        if (files.length > 0) {
            const imageUrl = URL.createObjectURL(files[0]);
            store.storeProfilePhoto(imageUrl);
            store.storeProfilePhotoFile(files);
        }
    };

};

const removeProfilePhoto = () => {
    const profileData = {
        userName: store.userName,
        image: null,
    };

    axios.post(`${store.config.apiHost}/removeProfilePhoto`, profileData)
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
}

const updateProfilePhoto = () => {

    const submitForm = document.getElementById('formSubmit');
    submitForm.click();

};

</script>
