
import { send } from '@/irc/client';
<template>
    <div class="overflow-hidden">
        <div class="fixed  top-0 h-screen w-screen bg-gray-500 z-50 opacity-40">
        </div>

        <div class="flex absolute top-0 justify-center w-full items-center h-screen">
            <div class="relative rounded-lg bg-white z-50 w-full max-w-xl p-6">
                <form @submit.prevent="onSubmit" class="text-left">
                    <div class="text-left">
                        <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Username is registered</h3>

                        <label for="nick" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                        <div class="mt-2">
                            <input type="password" name="nick" id="nick" v-model="password"
                                class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Password" />
                        </div>

                        <div class="mt-4">
                            <span class="text-red-600">{{ store.passwordError }}</span>
                        </div>

                        <button type="submit"
                            
                            class="mt-4 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Login</button>

                        <a href="" class="ml-4">Use another username</a>

                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { identify } from '@/irc/client.js';
import { useMainStore } from '@/mainStore';

const store = useMainStore();


const onSubmit = () => {
    store.setPasswordError(null);
    identify(password.value);
}

const password = ref("");
</script>
